import "shared/components/image-picker.js";
import { __ } from 'i18n';
import { _converse, api } from  "@converse/headless/core";
import { html } from "lit";


const tplOmemoPage = (el) => html`
    <div class="tab-pane ${ el.tab === 'omemo' ? 'active' : ''}" id="omemo-tabpanel" role="tabpanel" aria-labelledby="omemo-tab">
        ${ el.tab === 'omemo' ? html`<converse-omemo-profile></converse-omemo-profile>` : '' }
    </div>`;


export default (el) => {
    const o = { ...el.model.toJSON(), ...el.model.vcard.toJSON() };

    const i18n_email = __('Email');
    const i18n_fullname = __('Full Name');
    const i18n_jid = __('Nickname');
    const i18n_nickname = __('Nickname');
    const i18n_role = __('Role');
    const i18n_save = __('Save and close');
    const i18n_role_help = __('Use commas to separate multiple roles. Your roles are shown next to your name on your chat messages.');
    const i18n_url = __('URL');

    const i18n_omemo = __('OMEMO');
    const i18n_profile = __('Profile');
    const ii18n_reset_password = __('Reset Password');

    const navigation_tabs = [
        html`<li role="presentation" class="nav-item">
            <a class="nav-link ${el.tab === "profile" ? "active" : ""}"
               id="profile-tab"
               href="#profile-tabpanel"
               aria-controls="profile-tabpanel"
               role="tab"
               @click=${ev => el.switchTab(ev)}
               data-name="profile"
               data-toggle="tab">${ i18n_profile }</a>
            </li>`
    ];

    // navigation_tabs.push(
    //     html`<li role="presentation" class="nav-item">
    //             <a class="nav-link ${el.tab === "passwordreset" ? "active" : ""}"
    //             id="passwordreset-tab"
    //             href="#passwordreset-tabpanel"
    //             aria-controls="passwordreset-tabpanel"
    //             role="tab"
    //             @click=${ev => el.switchTab(ev)}
    //             data-name="passwordreset"
    //             data-toggle="tab">${ ii18n_reset_password }</a>
    //     </li>`
    // );

    if (_converse.pluggable.plugins['converse-omemo']?.enabled(_converse)) {
        navigation_tabs.push(
            html`<li role="presentation" class="nav-item">
                <a class="nav-link ${el.tab === "omemo" ? "active" : ""}"
                   id="omemo-tab"
                   href="#omemo-tabpanel"
                   aria-controls="omemo-tabpanel"
                   role="tab"
                   @click=${ev => el.switchTab(ev)}
                   data-name="omemo"
                   data-toggle="tab">${ i18n_omemo }</a>
            </li>`
        );
    }

    return html`
        <ul class="nav nav-pills justify-content-center">${navigation_tabs}</ul>
        <div class="tab-content">
            <div class="tab-pane ${ el.tab === 'profile' ? 'active' : ''}" id="profile-tabpanel" role="tabpanel" aria-labelledby="profile-tab">
                <form class="converse-form converse-form--modal profile-form" action="#" @submit=${ev => el.onFormSubmitted(ev)}>
                    <div class="row">
                        <div class="col-auto">
                            <converse-image-picker .data="${{image: o.image, image_type: o.image_type}}" width="128" height="128"></converse-image-picker>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="col-form-label">${i18n_jid}:</label>
                                <div>${ api.settings.get('i_hide_domain') ? o.jid.split('@')[0] : o.jid}</div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group">
                        <button type="submit" class="save-form btn btn-primary">${i18n_save}</button>
                    </div>
                </form>
            </div>

            ${ _converse.pluggable.plugins['converse-omemo']?.enabled(_converse) ? tplOmemoPage(el) : '' }
        </div>
    </div>`;
}
